import { lazy } from 'react'
import { RouteProps } from 'react-router'

export interface PrivateRouteObject extends RouteProps {
  exact: boolean
  path: string
  breadcrumb: string
  component: any
  title: string
}

const Dashboard = lazy(() => import('../containers/Dashboard'))
const Login = lazy(() => import('../containers/Login'))

const PRIVATE_ROUTES: PrivateRouteObject[] = [
  {
    exact: false,
    path: '/dashboard',
    breadcrumb: 'Dashboard',
    component: Dashboard,
    title: 'Dashboard'
  }
]

const PUBLIC_ROUTES = [
  {
    exact: true,
    title: 'Login',
    path: '/',
    component: Login
  },
  {
    exact: true,
    title: 'OAuth',
    path: '/oauth',
    component: Login
  }
]

export { PUBLIC_ROUTES, PRIVATE_ROUTES }
