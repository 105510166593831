import { theme as chakraTheme } from '@chakra-ui/core'
import images from './images'

export const flatUIColors = [
  '#1abc9c',
  '#16a085',
  '#2ecc71',
  '#27ae60',
  '#3498db',
  '#2980b9',
  '#9b59b6',
  '#8e44ad',
  '#f1c40f',
  '#f39c12',
  '#e67e22',
  '#d35400',
  '#e74c3c',
  '#c0392b',
  '#bdc3c7',
  '#95a5a6',
  '#7f8c8d',
  '#34495e',
  '#2c3e50'
]

const theme = {
  ...chakraTheme,
  fonts: {
    body: 'Helvetica, sans-serif',
    mono: 'Helvetica, sans-serif',
    heading: 'Helvetica, sans-serif'
  },
  colors: {
    ...chakraTheme.colors,
    background: flatUIColors[Math.floor(Math.random() * 18)],
    brand: {
      50: '#a6a6a6',
      100: '#8c8c8c',
      200: '#737373',
      300: '#595959',
      400: '#333333',
      500: '#222222',
      600: '#201418',
      700: '#201418',
      800: '#201418',
      900: '#201418'
    },
    accent: {
      50: '#fff7db',
      100: '#ffe5ae',
      200: '#fdd57f',
      300: '#fbc44e',
      400: '#fab31f',
      500: '#FAB423',
      600: '#af7700',
      700: '#7e5500',
      800: '#4c3300',
      900: '#1d1000'
    },
    success: {
      50: '#e3fbee',
      100: '#c3ebd7',
      200: '#a0dcbf',
      300: '#7ccda7',
      400: '#59bf8e',
      500: '#40a674',
      600: '#30815a',
      700: '#205c40',
      800: '#0e3825',
      900: '#001509'
    }
  },
  boxShadow: '0px 0px 4px 4px rgba(0,0,0,0.4)',
  gridGutter: 1 // rem - taken from Chakra UI space scale https://chakra-ui.com/theme#spacing
}

export { theme, images }
