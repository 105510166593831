import get from 'lodash/get'
import { STRAPI_USER_STORAGE_KEY } from '../constants'
/**
 * Gets JWT of authenticatedUser from either sessionStorage or localStorage
 */
export const fetchJwt = () => {
  const localUser = localStorage.getItem(STRAPI_USER_STORAGE_KEY)
  const sessionUser = sessionStorage.getItem(STRAPI_USER_STORAGE_KEY)
  const user = sessionUser || localUser

  return user ? JSON.parse(user).jwt : null
}

export function formatError({ response }: any) {
  return get(response, 'data.message[0].messages[0].message', 'An unknown error has occured.')
}

export function pickRandomProperty(obj: any): any {
  let result
  let count = 0
  for (let prop in obj) {
    if (Math.random() < 1 / ++count) {
      result = prop
    }
  }
  return result
}

export function urlToJson(search: string) {
  return JSON.parse(
    '{"' +
      decodeURI(search.substring(1))
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') +
      '"}'
  )
}
