import {
  Flex,
  Button,
  useDisclosure,
  Drawer,
  DrawerContent,
  DrawerCloseButton,
  DrawerOverlay,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Avatar,
  Divider,
  useToast,
  Tag,
  TagIcon,
  TagLabel
} from '@chakra-ui/core'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { color, ColorProps, space, SpaceProps } from 'styled-system'
import { Text } from '../../typography'
import {
  useJoinGameMutation,
  useUsersQuery,
  Enum_Game_Winner,
  Enum_Game_Status
} from '../../generated/graphql'
import { SUCCESS_TOAST, ERROR_TOAST } from '../../constants'
import { version } from '../../../package.json'

type HeaderProps = RouteComponentProps &
  ColorProps & {
    color?: string
    size?: number
    id?: string
    open?: boolean
    getLoggedInUser?: () => { name?: string; id: string }
    refetch?: () => any
  }

type HeaderContProps = SpaceProps &
  ColorProps & {
    color?: string
    open?: boolean
  }

const HeaderCont = styled(motion.div)<HeaderContProps>`
  ${space};
  ${color};
  top: 0;
  right: 0;
  height: 64px;
  z-index: 1290;
  display: flex;
  position: fixed;
  align-items: center;
  flex-direction: row;
  box-sizing: border-box;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: 40em) {
    left: 0;
  }
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
`

type PlayerProps = {
  user: any
}

const Player = (props: PlayerProps) => (
  <>
    <Flex>
      <Flex flex={1}>
        <Avatar size="md" src={props.user?.avatarUrl} name={props.user?.firstName} />
        <Flex ml="3" flexDirection="column" justifyContent="center">
          <Text fontWeight="bold">{props.user?.firstName}</Text>
          <Text fontSize="sm">Potato | Premier League</Text>
        </Flex>
      </Flex>
      <Flex flex={1} flexDirection="row" justifyContent="space-around">
        <Flex ml="3" flexDirection="column" justifyContent="center" alignItems="center">
          <Text fontWeight="bold">{props.user?.gamesCreated?.length}</Text>
          <Text fontSize="sm">
            Started &nbsp;
            <span role="img" aria-label="Zap">
              ⚡️
            </span>
          </Text>
        </Flex>
        <Flex ml="3" flexDirection="column" justifyContent="center" alignItems="center">
          <Text fontWeight="bold">{props.user?.played}</Text>
          <Text fontSize="sm">
            Played &nbsp;
            <span role="img" aria-label="Medal">
              🏅
            </span>
          </Text>
        </Flex>
        <Flex ml="3" flexDirection="column" justifyContent="center" alignItems="center">
          <Text fontWeight="bold">{props.user?.won}</Text>
          <Text fontSize="sm">
            Won &nbsp;
            <span role="img" aria-label="Trophy">
              🏆
            </span>
          </Text>
        </Flex>
      </Flex>
    </Flex>
    <Divider />
  </>
)

const Header: React.FC<HeaderProps> = ({ ...rest }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()
  const { data } = useUsersQuery()
  const [joinGame, { loading }] = useJoinGameMutation({
    onCompleted: () => {
      toast({ description: 'New game started! Lets go 💪', ...SUCCESS_TOAST })
      rest.refetch && rest.refetch()
    },
    onError: (e) => {
      toast({ description: e.message.replace('GraphQL error: Error: ', ''), ...ERROR_TOAST })
    }
  })

  const players = data?.users?.map((user) => {
    const played =
      // @ts-ignore
      user?.blackPlayer0?.filter((game) => game?.status === Enum_Game_Status.Finished)?.length +
      // @ts-ignore
      user?.blackPlayer1?.filter((game) => game?.status === Enum_Game_Status.Finished).length +
      // @ts-ignore
      user?.whitePlayer0?.filter((game) => game?.status === Enum_Game_Status.Finished).length +
      // @ts-ignore
      user?.whitePlayer1?.filter((game) => game?.status === Enum_Game_Status.Finished).length
    const won =
      // @ts-ignore
      user?.blackPlayer0?.filter((game) => game?.winner === Enum_Game_Winner.Black)?.length +
      // @ts-ignore
      user?.blackPlayer1?.filter((game) => game?.winner === Enum_Game_Winner.Black)?.length +
      // @ts-ignore
      user?.whitePlayer0?.filter((game) => game?.winner === Enum_Game_Winner.White)?.length +
      // @ts-ignore
      user?.whitePlayer1?.filter((game) => game?.winner === Enum_Game_Winner.White)?.length
    return {
      ...user,
      played,
      won
    }
  })

  return (
    <HeaderCont px={4} {...rest}>
      <Drawer size="md" isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader display="flex" justifyContent="center" alignItems="center">
            FOOS LEADERBOARD &nbsp;
            <span role="img" aria-label="Trophy">
              🏆
            </span>
          </DrawerHeader>
          <Divider />
          <DrawerBody overflow="scroll">
            <Flex flexDirection="column">
              {players?.map((user) => (
                <Player user={user} />
              ))}
            </Flex>
          </DrawerBody>
          <DrawerFooter>
            <Flex flex={1} flexDirection="row" justifyContent="space-between" alignItems="center">
              <Tag size="lg" variantColor="gray">
                <TagIcon icon="at-sign" size="12px" />
                <TagLabel>{version}</TagLabel>
              </Tag>
              <Button onClick={onClose} color="blue">
                Done
              </Button>
            </Flex>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <Button onClick={onOpen}>
        LEADERBOARD &nbsp;
        <span role="img" aria-label="Trophy">
          🏆
        </span>
      </Button>
      <Button isLoading={loading} onClick={() => joinGame()}>
        I WANNA FOOS! &nbsp;
        <span role="img" aria-label="Trophy">
          🎉
        </span>
      </Button>
    </HeaderCont>
  )
}

export default withRouter(Header)

Header.defaultProps = {
  bg: 'rgba(0, 0, 0, 0.1)'
}
